.quick-links-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.quick-link-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 120px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  text-decoration: none;
  color: #333;
  transition: transform 0.2s, box-shadow 0.2s;
}

.quick-link-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.quick-link-image {
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
}

.quick-link-title {
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
}
